<template>
  <div>
    <div class="banner" v-if="!isMobile">
      <el-carousel :interval="2000" arrow="never">
        <el-carousel-item v-for="(item, index) in imageList" :key="index" :style="{'background-color': item.background}">
          <img :src="item.idView" alt="carousel-item" style="height: 100%; width: 1920px; margin: 0 auto"/>
          <div class="carousel-item-text">
            <div class="box-all">
              <div class="box-foot"></div>
            </div>
            <div class="font-family-AlimamaShuHeiTi">{{ item.title }}</div>
            <div class="description">{{ item.description }}</div>
            <div class="btn" @click="goto"><el-button>开始转运<i class="el-icon-right margin-l-10"></i></el-button></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else class="m-banner">
      <el-carousel :interval="1500" arrow="never" height="5rem">
        <el-carousel-item v-for="(item, index) in imageList" :key="index">
          <img :src="item.idView" alt="banner" style="width: 100%" />
          <div class="m-carousel-item-text">
            <div class="m-box-all">
              <div class="m-box-foot"></div>
            </div>
            <div class="m-title">{{ item.title }}</div>
            <div class="m-description">{{ item.description }}</div>
            <div class="m-btn" @click="$router.push('/coming-soon')"><el-button>开始转运<i class="el-icon-right margin-l-10"></i></el-button></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import bannerOne from '../assets/image/home/banner-one.jpg'
import bannerTwo from '../assets/image/home/banner-two.jpg'
import { isMobileDevice } from '../utils/detectDevice';

export default {
  name: "banner",
  data() {
    return {
      bannerOne,
      bannerTwo,
      isMobile: isMobileDevice(),
      imageList: [
        { id: 1, idView: bannerOne, background: '#091e47', title: '一带一路，互联互通', description: 'THE BELT AND ROAD, INTERCONNECTION'},
        { id: 2, idView: bannerTwo, background: '#153672', title: '内外贸整合，优质国货，通商全球', description: 'INTEGRATION OF DOMESTIC AND FOREIGN TRADE, HIGH-QUALITY DOMESTIC PRODUCTS, GLOBAL TRADE'},
      ],
      isDragging: false
    }
  },
  methods: {
    goto() {
      let routeUrl =  this.$router.resolve({ path: './coming-soon'})
      window.open(routeUrl.href, '_blank');
    }
  }
};
</script>

<style scoped>
.banner {
  width: 100%;
}
::v-deep .el-carousel__container {
  /* 设置宽度、最大宽度等 */
  width: 100%;
  height: 960px;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
  position: relative;
}
::v-deep .el-carousel__item .is-active .is-animating {
  width: 100%;
  height: 100%;
  background-color: rgb(9,30,71);
}
::v-deep .banner .el-carousel__indicators--horizontal {
  bottom: 30px;
}
.carousel-item-text {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  box-sizing: border-box;
  text-align: center;
  width: 1280px;
  margin: 0 auto;
}
.box-all{
  width: 450px;
  height: 180px;
  border-top: 8px solid #ffffff;
  border-bottom: 8px solid #ffffff;
  z-index: 20;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
}
.box-all:before, .box-all:after{
  position:absolute;
  width: 0px;
  height: 15px;
  content: "";
  top: 0;
}
.box-all:before, .box-foot:before{
  border-left: 8px solid #ffffff;
  left: 0px;
}
.box-all:after,.box-foot:after{
  border-right: 8px solid #ffffff;
  right: 0;
}
.box-foot{
  position:absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.box-foot:before, .box-foot:after{
  position:absolute;
  width: 0px;
  height: 15px;
  content: "";
  bottom: 0;
}
.description {
  font-size: 24px;
  margin: 60px 0 70px 0;
}
.btn .el-button--small {
  padding: 13px 28px;
  color: #102867;
  font-size: 20px;
}
/*.btn:hover {*/
/*  transform: translateY(-4px);*/
/*}*/
.btn .el-button:focus, .el-button:hover {
  border-color: white;
  background-color: white;
}
/*移动端*/
.m-swiper-container {
  width: 100vw;
}
.m-carousel-item-text {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  box-sizing: border-box;
  text-align: center;
}
.m-box-all{
  width: 3rem;
  height: 1.15rem;
  border-top: .05rem solid #ffffff;
  border-bottom: .05rem solid #ffffff;
  z-index: 20;
  position: absolute;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
}
.m-box-all:before, .m-box-all:after{
  position:absolute;
  width: 0px;
  height: .12rem;
  content: "";
  top: 0;
}
.m-box-all:before, .m-box-foot:before{
  border-left: .05rem solid #ffffff;
  left: 0px;
}
.m-box-all:after,.m-box-foot:after{
  border-right: .05rem solid #ffffff;
  right: 0;
}
.m-box-foot{
  position:absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.m-box-foot:before, .m-box-foot:after{
  position:absolute;
  width: 0px;
  height: .12rem;
  content: "";
  bottom: 0;
}
.m-title {
  font-size: .5rem;
  font-family: AlimamaShuHeiTi;
}
.m-description {
  font-size: .18rem;
  margin: .35rem 0 .12rem 0
}
.m-btn .el-button--small {
  padding: .2rem .33rem .19rem .33rem;
  color: #102867;
  font-size: 0.28rem;
}

::v-deep .m-banner .el-carousel__button {
  width: .1rem;
  height: .1rem;
  background-color: #ffffff;
  border-radius: 50%;
}
::v-deep .banner .el-carousel__button {
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  border-radius: 50%;
}
::v-deep .banner .el-carousel__indicator.is-active button:before{
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 3px solid #ffffff;
}
::v-deep .m-banner .el-carousel__indicator--horizontal {
  padding: 0 .1rem;
}
</style>
