<template>
  <div class="home">
    <div class="margin-b-100" v-if="!isMobile"><banner/></div>
    <div v-else><banner/></div>
    <div class="xz" v-if="!isMobile">
      <div class="font-family-AlimamaShuHeiTi text-align-center font-50 title">“先知”</div>
      <div class="text-align-center desc">出海选品决策咨询</div>
      <div class="text-align-center xz-content">
        <ul>
          <li class="home-gird">
            <div class="analyze">
              <p class="margin-t-40 font-22 font-weight-bold">大数据分析</p>
              <div class="margin-t-20 analyze-desc">
                <p class="font-18">工厂型企业分析自身产品的市</p>
                <p class="font-18">场空间、竞品概况、准入标</p>
                <p class="font-18">准、优化建议等</p>
              </div>
            </div>
          </li>
          <li class="home-gird">
            <div class="analyze">
              <p class="margin-t-40 font-22 font-weight-bold">提升转化率</p>
              <div class="margin-t-20 analyze-desc">
                <p class="font-18">帮助企业更高效的完成运营操</p>
                <p class="font-18">作，更高转化率的广告投放等</p>
              </div>
            </div>
          </li>
          <li class="home-gird">
            <div class="analyze">
              <p class="margin-t-40 font-22 font-weight-bold">精准选品</p>
              <div class="margin-t-20 analyze-desc">
                <p class="font-18">贸易型企业根据自身条件可实</p>
                <p class="font-18">现精准选品，提高选品准确度</p>
              </div>
            </div>
          </li>
        </ul>
        <div class="btn margin-t-220" @click="goto"><el-button>进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
      </div>
    </div>
    <div v-else class="m-xz">
      <div class="font-family-AlimamaShuHeiTi text-align-center title m-font-title">“先知”</div>
      <div class="text-align-center m-desc">出海选品决策咨询</div>
      <div class="text-align-center m-xz-content">
        <div style="height: 7.5rem">
          <div class="m-home-gird">
            <div class="analyze">
              <p style="color: #121212" class="font-weight-bold m-dsj">大数据分析</p>
              <div class="m-analyze-desc">
                <p>工厂型企业分析自身产品的市场空间、竞品概况、</p>
                <p>准入标准、优化建议等</p>
                <p></p>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 7.5rem">
          <div class="m-home-gird box1">
            <div class="analyze">
              <p style="color: #121212" class="font-weight-bold m-dsj">提升转化率</p>
              <div class="m-analyze-desc">
                <p>帮助企业更高效的完成运营操更高转化率的广</p>
                <p>告投放等</p>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 7.5rem">
          <div class="m-home-gird box2">
            <div class="analyze">
              <p style="color: #121212" class="font-weight-bold m-dsj">精准选品</p>
              <div class="m-analyze-desc">
                <p>贸易型企业根据自身条件可实现精准选品，提高</p>
                <p>选品准确度</p>
              </div>
            </div>
          </div>
        </div>
        <div class="m-xz-btn" @click="$router.push('/coming-soon')"><el-button>进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
      </div>
    </div>

    <div class="bg krt-bg" v-if="!isMobile">
      <div class="content">
        <div class="font-family-AlimamaShuHeiTi text-align-center font-50 white">“科若通”</div>
        <div class="text-align-center desc-plan">产业带特色跨境B2B外贸综合服务</div>
        <div class="text-align-center krt-content">
          <ul>
            <li class="krt-gird">
              <p class="margin-t-40 font-22 font-weight-bold white">特色体系</p>
              <div class="margin-t-20">
                <p class="font-18 white">打通地方综合试验区公服，支持产业</p>
                <p class="font-18 white">带特色外贸综合服务体系搭建</p>
              </div>
            </li>
            <li class="krt-gird" style="margin: 0 40px">
              <p class="margin-t-40 font-22 font-weight-bold white">贸易转型</p>
              <div class="margin-t-20">
                <p class="font-18 white">推动贸易数据本地化，支持传统贸易</p>
                <p class="font-18 white">转型申报跨境电商B2B(9710/9810)</p>
              </div>
            </li>
            <li class="krt-gird">
              <p class="margin-t-40 font-22 font-weight-bold white">专项支持</p>
              <div class="margin-t-20">
                <p class="font-18 white">应对“无票免征”以及快速退税等需</p>
                <p class="font-18 white">求，支持特殊区域出口海外仓(1210)</p>
              </div>
            </li>
          </ul>
          <div class="btn-serve"><el-button @click="goto">进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
        </div>
        <div class="map"></div>
      </div>
    </div>
    <div v-else class="m-krt-bg">
      <div class="m-content">
        <div class="font-family-AlimamaShuHeiTi text-align-center white m-font-title">“科若通”</div>
        <div class="text-align-center m-desc-plan white">产业带特色跨境B2B外贸综合服务</div>
        <div class="text-align-center m-krt-content">
          <div style="margin-bottom: .75rem" >
            <div class="m-krt-gird white">
              <p style="font-size: .38rem; padding-top: .35rem" class="font-weight-bold">特色体系</p>
              <div style="font-size: .28rem; margin-top: .3rem">
                <p>打通地方综合试验区公服，支持产业带特色外贸综合</p>
                <p>服务体系搭建</p>
              </div>
            </div>
            <div class="m-krt-gird white">
              <p style="font-size: .38rem; padding-top: .35rem" class="font-weight-bold">贸易转型</p>
              <div style="font-size: .28rem; margin-top: .3rem">
                <p>推动贸易数据本地化，支持传统贸易转型申报跨境</p>
                <p>电商B2B(9710/9810)</p>
              </div>
            </div>
            <div class="m-krt-gird white">
              <p style="font-size: .38rem; padding-top: .35rem" class="font-weight-bold">专项支持</p>
              <div style="font-size: .28rem; margin-top: .3rem">
                <p>应对“无票免征”以及快速退税等需求，支持特殊</p>
                <p>区域出口海外仓(1210)</p>
              </div>
            </div>
          </div>
          <div class="m-btn-serve" @click="$router.push('/coming-soon')"><el-button>进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
        </div>
      </div>
      <div class="m-map"></div>

    </div>

    <div class="bg chuyin-bg" v-if="!isMobile">
      <div class="content" >
        <div class="font-family-AlimamaShuHeiTi text-align-center font-50 title">“雏鹰”</div>
        <div class="text-align-center desc">人才培养以及输送系统</div>
        <div class="text-align-center krt-content">
          <ul>
            <li class="chuyin-gird">
              <div class="chuyin-img"><img src="../assets/image/home/cy-one.png" alt=""></div>
              <p class="margin-t-40 font-22 font-weight-bold title">解决痛点</p>
              <div class="margin-t-20 color-11142c">
                <p class="font-18">企业人才结构解决方案，搭建</p>
                <p class="font-18">企业与高校的桥梁</p>
              </div>
            </li>
            <li class="chuyin-gird">
              <div class="chuyin-img"><img src="../assets/image/home/cy-two.png" alt=""></div>
              <p class="margin-t-40 font-22 font-weight-bold title">精准定位</p>
              <div class="margin-t-20 color-11142c">
                <p class="font-18">岗位要求与专业能力精准匹</p>
                <p class="font-18">配，解决“可佣不可用”问题</p>
              </div>
            </li>
            <li class="chuyin-gird">
              <div class="chuyin-img"><img src="../assets/image/home/cy-three.png" alt=""></div>
              <p class="margin-t-40 font-22 font-weight-bold title">本地化</p>
              <div class="margin-t-20 color-11142c">
                <p class="font-18">“数字游民”网络人才库，解决</p>
                <p class="font-18">人才本地化难的问题</p>
              </div>
            </li>
          </ul>
          <div class="btn" @click="goto"><el-button class="margin-t-20">进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
        </div>
      </div>
    </div>

    <div v-else class="m-chuyin-bg">
      <div class="m-content-chuyin">
        <div style="padding-top: .5rem" class="font-family-AlimamaShuHeiTi text-align-center m-font-title title">“雏鹰”</div>
        <div class="text-align-center m-desc">人才培养以及输送系统</div>
      </div>
      <div class="m-chuyin-cont">
        <div class="m-chuyin-img"><img src="../assets/image/home/cy-one.png" alt=""></div>
        <p class="font-weight-bold m-dsj text-align-center" style="color: #102867">解决痛点</p>
        <div class="color-11142c m-analyze-desc text-align-center">
          <p>企业人才结构解决方案，搭建企业与高校的桥梁</p>
          <p></p>
        </div>
      </div>
      <div class="m-chuyin-cont" style="margin: .5rem auto">
        <div class="m-chuyin-img"><img src="../assets/image/home/cy-two.png" alt=""></div>
        <p class="font-weight-bold m-dsj text-align-center" style="color: #102867">精准定位</p>
        <div class="color-11142c m-analyze-desc text-align-center">
          <p>岗位要求与专业能力精准匹配，解决“可佣不可用”问题</p>
          <p></p>
        </div>
      </div>
      <div class="m-chuyin-cont">
        <div class="m-chuyin-img"><img src="../assets/image/home/cy-three.png" alt=""></div>
        <p class="font-weight-bold m-dsj text-align-center" style="color: #102867">本地化</p>
        <div class="color-11142c m-analyze-desc text-align-center">
          <p>“数字游民”网络人才库，解决人才本地化难的问题</p>
          <p></p>
        </div>
      </div>
      <div style="margin-top: .8rem" class="m-xz-btn" @click="$router.push('/coming-soon')"><el-button>进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
    </div>

    <div class="bg pilotage-plan-bg" v-if="!isMobile">
      <div class="content content-bg">
        <div class="font-family-AlimamaShuHeiTi text-align-center font-50 white padding-t-50">“领航计划”</div>
        <div class="text-align-center desc-plan">数字化转型运营服务</div>
        <div class="text-align-center krt-content">
          <ul>
            <li class="lh-gird">
              <div class="margin-t-60 chuyin-icon"><img src="../assets/image/home/lh-one.png" alt=""></div>
              <p class="margin-t-40 font-22 font-weight-bold white">运营服务</p>
              <div class="margin-t-20 white">
                <p class="font-18">提供产业带出海标杆企业的运营服</p>
                <p class="font-18">务，解决企业“想而不会”的问题</p>
              </div>
            </li>
            <li class="lh-gird">
              <div class="margin-t-60 chuyin-icon"><img src="../assets/image/home/lh-two.png" alt=""></div>
              <p class="margin-t-40 font-22 font-weight-bold white">产业带赋能</p>
              <div class="margin-t-20 white">
                <p class="font-18">通过运营服务提炼产业带特色的出</p>
                <p class="font-18">海赋能矩阵，完善出海赋能生态，</p>
                <p class="font-18">打造赋能矩阵</p>
              </div>
            </li>
            <li class="lh-gird">
              <div class="margin-t-60 chuyin-icon"><img src="../assets/image/home/lh-three.png" alt=""></div>
              <p class="margin-t-40 font-22 font-weight-bold white">专项服务</p>
              <div class="margin-t-20 white">
                <p class="font-18">遴选微品牌的“灯塔工厂”推动联合</p>
                <p class="font-18">运营服务，并加入转运供应链</p>
              </div>
            </li>
          </ul>
          <div class="btn"><el-button @click="into" class="margin-t-20">进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
        </div>
      </div>
    </div>
    <div v-else class="m-pilotage-plan-bg">
      <div class="m-pilotage">
        <div style="padding-top: .5rem" class="font-family-AlimamaShuHeiTi text-align-center white m-font-title">“领航计划”</div>
        <div class="text-align-center m-desc-plan white">数字化转型运营服务</div>
        <div class="m-pilotage-gird text-align-center white">
          <div class="m-chuyin-icon"><img src="../assets/image/home/lh-one.png" alt=""></div>
          <p style="font-size: .38rem; padding: .2rem 0">运营服务</p>
          <div class="white" style="font-size: .28rem; margin: 0 auto">
            <p>提供产业带出海标杆企业的运营服务，解决企业</p>
            <p>“想而不会”的问题</p>
          </div>
        </div>
        <div class="m-pilotage-gird text-align-center white" style="margin: 1rem auto">
          <div class="m-chuyin-icon"><img src="../assets/image/home/lh-two.png" alt=""></div>
          <p style="font-size: .38rem; padding: .15rem 0">产业带赋能</p>
          <div class="white" style="font-size: .28rem; margin: 0 auto">
            <p>通过运营服务提炼产业带特色的出海赋能矩阵，完</p>
            <p>善出海赋能生态，打造赋能矩阵</p>
          </div>
        </div>
        <div class="m-pilotage-gird text-align-center white">
          <div class="m-chuyin-icon"><img src="../assets/image/home/lh-three.png" alt=""></div>
          <p style="font-size: .38rem; padding: .2rem 0">专项服务</p>
          <div class="white" style="font-size: .28rem; margin: 0 auto">
            <p>遴选微品牌的“灯塔工厂”推动联合运营服务，</p>
            <p>并加入转运供应链</p>
          </div>
        </div>
        <div style="margin-top: 1rem" class="m-btn-serve" @click="$router.push('/coming-soon')"><el-button>进入服务<i class="el-icon-right margin-l-10"></i></el-button></div>
      </div>
    </div>

  </div>
</template>

<script>
import banner from "../components/banner";
import { isMobileDevice } from '../utils/detectDevice';
export default {
  name: "index",
  components: { banner },
  data() {
    return {
      isMobile: isMobileDevice()
    }
  },
  methods: {
    goto() {
      let routeUrl =  this.$router.resolve({ path: './coming-soon'})
      window.open(routeUrl.href, '_blank');
    },
    into() {
      window.open('https://lhjh.crossea.com', '_blank');
    },
  }
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
}
.title {
  color: #102867;
}
.desc {
  color: #222222;
  margin: 20px 0 40px 0;
  font-size: 26px;
}
.desc-plan {
  color: #ffffff;
  margin: 20px 0 40px 0;
  font-size: 26px;
}
.home-gird {
  width: 400px;
  height: 320px;
  position: relative;
}
.xz {
  width: 1280px;
  height: 860px;
  margin: 0 auto;
}
.xz-content {
  width: 100%;
}
.xz-content ul li {
  display: inline-block;
  background: url("../assets/image/home/xz-one.png") no-repeat center center;
}
.xz-content ul li:nth-child(2) {
  margin: 0 40px;
  background: url("../assets/image/home/xz-two.png") no-repeat center center;
}
.xz-content ul li:nth-child(3) {
  background: url("../assets/image/home/xz-three.png") no-repeat center center;
}
.home-gird .analyze {
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  width: 320px;
  height: 220px;
  border-radius: 4px;
  color: #121212;
  box-shadow: 0px 4px 20px 0px #0000000D;
}
.analyze-desc {
  color: #222222;
}
.btn .el-button--small {
  padding: 13px 28px;
  color: #ffffff;
  font-size: 20px;
}
.btn .el-button {
  width: 168px;
  height: 48px;
  background-color: #102867;
  border-color: #102867;
}

/* 科若通*/
.bg {
  width: 100%;
  height: 960px;
  padding-top: 120px;
}
.krt-bg {
  background-color: #102867;
  position: relative;
}
.map{
  width: 1600px;
  height: 380px;
  background: url("../assets/image/home/krt.png") no-repeat bottom;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.content {
  width: 1280px;
  margin: 0 auto;
}
.krt-content {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
}
.krt-content ul li {
  float: left;
}
.krt-gird {
  width: 400px;
  height: 260px;
}
.btn-serve .el-button--small {
  padding: 13px 28px;
  color: #102867;
  font-size: 20px;
}
/*“雏鹰”*/
.chuyin-bg {
  background: url("../assets/image/home/cy.png") no-repeat;
  background-size: cover;
}
.chuyin-bg ul {
  width: 1280px;
  height: 420px;
  margin-bottom: 80px;
}
.chuyin-gird {
  width: 380px;
  height: 420px;
  background-color: #ffffff;
  border-radius: 4px;
  opacity: .8;
}
.chuyin-gird:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.18);
}
.krt-content ul .chuyin-gird:nth-child(2) {
  margin: 0 70px;
}
.chuyin-gird .chuyin-img{
  width: 100%;
  height: 220px;
  background-size: 100%;
}
.color-11142c {
  color: #11142C;
}

/*“领航计划”*/
.pilotage-plan-bg {
  background: url("../assets/image/home/lh.png") no-repeat;
  background-size: cover;
}
.content-bg {
  width: 1280px;
  height: 720px;
  background-color: rgba(255, 255, 255, .1);
}
.pilotage-plan-bg .krt-content{
  margin-top: 20px;
}
.pilotage-plan-bg ul {
  width: 100%;
  height: 350px;
}
.lh-gird {
  width: 380px;
  height: 350px;
}
.chuyin-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}
.krt-content ul .lh-gird:nth-child(2) {
  margin: 0 70px;
}
</style>

<!--移动端-->
<style scoped>
.m-xz {
  width: 100vw;
  height: 25.8rem;
  margin-top: .5rem;
}
.m-font-title {
  font-size: .55rem
}
.m-desc {
  color: #222222;
  margin: .1rem 0 .5rem 0;
  font-size: .3rem;
}

.m-xz-content {
  width: 100%;
  height: 11rem;
  margin: 0 auto;
  padding: 0 .5rem;
}
.m-xz-content .m-home-gird{
  width: 100%;
  height: 5rem;
}
.m-home-gird {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: url("../assets/image/home/xz-one.png") no-repeat center top;
  background-size: cover;
}
.box1 {
  background: url("../assets/image/home/xz-two.png") no-repeat center top;
  background-size: cover;
}
.box2 {
  background: url("../assets/image/home/xz-three.png") no-repeat center top;
  background-size: cover;
}
.m-home-gird .analyze{
  position: absolute;
  bottom: -35%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  width: 90%;
  height: 2.6rem;
  border-radius: .04rem;
  color: #121212;
  box-shadow: 0 .04rem .2rem 0 #0000000D;
}
.m-dsj {
  font-size: .38rem;
  margin: .35rem 0 .3rem 0;
}
.m-analyze-desc {
  color: #222222;
  font-size: .28rem;
}
.m-xz-btn .el-button--small {
  margin: 0 auto;
  padding: .2rem 0.33rem .19rem .33rem;
  color: #ffffff;
  font-size: 0.28rem;
  background-color: #102867;
  display: block;
}
.m-content {
  width: 100vm;
  height: 15.5rem;
  padding-top: .5rem;
}
.m-desc-plan {
  margin: .1rem 0 .5rem 0;
  font-size: .3rem;
}
.m-krt-bg {
  background-color: #102867;
  position: relative;
}
.m-map {
  width: 100vw;
  height: 2.8rem;
  background: url("../assets/image/home/krt.png") no-repeat top center;
  background-size: 150%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.m-krt-content {
  width: 100vw;
}
.m-krt-gird {
  width: 90%;
  height: 2.5rem;
  margin: .3rem auto;
  background-color: rgba(255,255,255,.1);
  border-radius: .05rem;
}
.m-btn-serve .el-button--small {
  margin: 0 auto;
  color: #102867;
  background-color: #ffffff;
  display: block;
  padding: .2rem 0.33rem .19rem .33rem;
  font-size: 0.28rem;
}
.m-chuyin-bg {
  width: 100vw;
  height: 27.5rem;
  padding: 0 .5rem;
  background: url("../assets/image/home/cy.png") no-repeat center center;
  background-size: cover;
}
.m-chuyin-cont {
  width: 100%;
  height: 7.3rem;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 .04rem .2rem 0 #0000000D;
}
.m-chuyin-cont .m-chuyin-img {
  width: 100%;
}
.m-pilotage-plan-bg {
  width: 100vm;
  height: 16rem;
  padding-top: 1rem;
  background: url("../assets/image/home/lh.png") no-repeat left center;
  background-size: cover;
}
.m-pilotage {
  width: 90%;
  height: 14rem;
  padding: 0 .5rem;
  background-color: rgba(255,255,255,.1);
  margin: 0 auto;
}
.m-pilotage-gird {
  width: 90%;
  height: 2.3rem;
  border-radius: .04rem;
  margin: 0 auto;
}
.m-chuyin-icon {
  margin: 0 auto;
  width: .8rem;
  height: .8rem;
}
</style>
